<template>
  <el-card class="card-normal blog" shadow="never">
    <el-row :gutter="20">
      <el-col :span="8" class="is-flex">
        <img
          v-if="data.productImage != ''"
          :src="imgUrlTERAK + data.productImage"
          alt=""
          @click="$emit('morePicture', imgUrlTERAK + data.productImage)"
          class="img-content cursor-pointer"
        />
        <img
          v-else
          :src="require(`@/assets/image-default.jpg`)"
          alt=""
          class="img-content"
        />
      </el-col>
      <el-col :span="16" class="pd-y-4">
        <div style="position: relative">
          <p class="title">{{ data.productName }}</p>
          <!-- <p>{{ data.subTitle }}</p> -->
          <p v-if="page != 'project'" class="mg-b-4">
            ต้องการให้ทางธนาคารเพื่อการส่งออกและนำเข้าแห่งประเทศไทย ติดต่อ
          </p>
          <p v-else class="mg-b-4">
            <span v-if="data.registerType == 1">โครงการของหน่วยงาน EXAC</span>
            <span v-else-if="data.registerType == 2"
              >โครงการของหน่วยงานพันธมิตร</span
            >
          </p>
          <p>
            คุณ
            <span class="color-75 mg-l-7" v-if="page == 'project'">
              {{ data.name + " " + data.surname }}
            </span>

            <!-- <span
              class="color-75 mg-l-7"
              v-else-if="
                data.contact.name == '' && data.contact.surname == ''
              "
            >
              {{ data.name + " " + data.surname }}</span
            > -->
            <span class="color-75 mg-l-7" v-else-if="!data.contact">
              {{ data.name + " " + data.surname }}</span
            >
            <span class="color-75 mg-l-7" v-else>
              {{ data.contact.name + " " + data.contact.surname }}</span
            >
          </p>
          <p>
            เบอร์โทรศัพท์
            <span class="color-75 mg-l-7" v-if="page == 'project'">
              {{ data.mobilephone }}
            </span>
            <span class="color-75 mg-l-7" v-else-if="!data.contact">
              {{ data.mobilephone }}</span
            >
            <span class="color-75 mg-l-7" v-else>
              {{ data.contact.mobilephone }}</span
            >
          </p>
          <p>
            อีเมล
            <span class="color-75 mg-l-7" v-if="page == 'project'">
              {{ data.email }}
            </span>
            <span class="color-75 mg-l-7" v-else-if="data.contact">
              {{ data.email }}</span
            >
            <span class="color-75 mg-l-7" v-else-if="data.contact != null">
              {{ data.contact.email }}</span
            >
            <span class="color-75 mg-l-7" v-else>-</span>
          </p>
          <p v-if="page != 'project' && page != 'insurance'">
            สาขาที่ท่านสนใจให้เจ้าหน้าที่ติดต่อกลับ

            <!-- ยังไม่แน่ใจ  data.branchContact -->
            <span class="color-75 mg-l-7" v-if="data.contact != null">
              {{ data.contact.branch }}</span
            >
            <span class="color-75 mg-l-7" v-else>-</span>
          </p>
        </div>
      </el-col>
    </el-row>
  </el-card>
</template>
<script>
export default {
  props: {
    data: {
      type: Object,
      default: null,
    },
    page: {
      type: String,
      default: null,
    },
  },
  mounted() {
    this.urlImage = `ttp://206.189.154.242:3081/api/public/images/${this.data.productImage}`;
  },
  data() {
    return {
      urlImage: null,
      imgUrlTERAK: process.env.VUE_APP_BASE_URL_TERAK_IMAGE,
    };
  },
};
</script>
