<template>
  <DefaultTemplate v-if="loading">
    <div class="box-name-page">
      <div>
        <el-breadcrumb
          separator-class="el-icon-arrow-right"
          class="set-breadcrumb"
        >
          <el-breadcrumb-item :to="{ path: '/user/detail/' + criterionId }"
            >จัดการข้อมูลผู้ประกอบการ</el-breadcrumb-item
          >
          <el-breadcrumb-item>ข้อมูลประกันการส่งออก</el-breadcrumb-item>
        </el-breadcrumb>
        <p class="title mg-t-4">ข้อมูลประกันการส่งออก</p>
      </div>
    </div>
    <el-row :gutter="30">
      <el-col
        :span="24"
        v-for="(row, index) in dataInsurances"
        :key="index"
        class="mg-y-5 pos-relative"
      >
        <CardBlog
          :data="row"
          @morePicture="openDialogPicture"
          :page="`insurance`"
        />
      </el-col>
    </el-row>
    <el-dialog
      class="dialog-custom user-list"
      v-if="dialogPicture"
      :visible.sync="dialogPicture"
      width="70%"
      top="10vh"
      center
    >
      <img :src="imgMore" alt="" class="w-100" />
    </el-dialog>
  </DefaultTemplate>
</template>

<script>
import DefaultTemplate from "@/template/DefaultTemplate";
import CardBlog from "@/components/card/CardBlog";
import { HTTP } from "@/service/axios";
import { mapState } from "vuex";

export default {
  components: {
    DefaultTemplate,
    CardBlog,
  },
  data() {
    return {
      loading: false,
      wordCredit: "",
      dataInsurances: [],
      imgMore: "",
      dialogPicture: false,
      juristicId: this.$route.params.id,
      criterionId: this.$route.params.criterionId,
      branch: this.$route.params.branch,
    };
  },
  mounted() {
    this.fetchInsuranceDetails();
  },
  methods: {
    openDialogPicture(getImg) {
      this.imgMore = getImg;
      this.dialogPicture = true;
    },
    async fetchInsuranceDetails() {
      try {
        // extract branch fetch details
        HTTP.defaults.headers.common["Authorization"] = `Bearer ${this.token}`;
        var res = null;
        if (this.branch == "TERAK") {
          let obj = {
            juristicId: this.juristicId,
            criterionId: this.criterionId,
          };
          res = await this.$store.dispatch("user/fetchInterestInsurance", obj);
        } else if (this.branch == "Channel Integration") {
          let obj = {
            juristicId: this.juristicId,
            criterionId: this.criterionId,
            type: "insurance",
          };
          res = await this.$store.dispatch(
            "user/fetchChannelInterestProduct",
            obj
          );
        }
        // check argument invalid
        if (typeof res == "object") {
          // define insurance details
          if (res.success) this.dataInsurances = res.obj;
          this.loading = true;
          return;
        }
        return;
      } catch (e) {
        console.log("err: ", e);
        return;
      }

      // const response = await HTTP.post(
      //   "user/interest/insurance",
      //   request,
      //   config
      // ).catch((e) => {
      //   return {
      //     data: {
      //       data: e,
      //       success: false,
      //     },
      //   };
      // });
      // // let arrCredit = this.dataInsurances.filter((a) => a._id == 1);
      // this.dataInsurances = response.data.obj;
      // this.loading = true;
      // return response;
    },
  },
  computed: {
    ...mapState({
      token: (state) => state._token,
    }),
  },
};
</script>
